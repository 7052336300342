export default class Validators {
    static notUndefined(inputField) {
        if (inputField !== undefined) return true;
    }

    static onlyLetters(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+$/;
        const result = regex.test(inputField);
        return result;
    }

    static lettersAndNumbers(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[A-Za-z0-9]+$/; // ammessi solo numeri e lettere maiuscole e minuscole ovunque
        const result = regex.test(inputField);
        return result;
    }

    static lettersNumbersAndSpaces(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[A-Za-z0-9 ]+$/; // ammessi solo numeri e lettere maiuscole e minuscole ovunque | permessi gli spazi
        const result = regex.test(inputField);
        return result;
    }

    static email(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/; // ammesse lettere e numeri | obbligatoria una e una sola @ | obbligatorio almeno un . | vietati più . di seguito | vietato partire o finire con . o @ | vietati caratteri speciali
        const result = regex.test(inputField);
        return result;
    }

    static marketDataAndDepositModelsNames(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[A-Za-z0-9_.-]{1,64}$/; // permessi numeri e lettere maiuscole e minuscole | permessi _ . - | non permessi caratteri speciali | limite minimo 1 | limite massimo 64
        const result = regex.test(inputField);
        return result;
    }

    static reportsAndAnalysisNames(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[A-Za-z0-9_.\-@#$%&+ ]{1,256}$/; // permessi numeri e lettere maiuscole e minuscole | permessi _ . - @ # $ % & + | permessi gli spazi | limite minimo 1 | limite massimo 256 |
        const result = regex.test(inputField);
        return result;
    }

    static processesNames(inputField) {
        if (inputField === undefined) {
            return undefined;
        }
        const regex = /^[a-z]+[a-z0-9_]*$/; // permessi numeri e lettere minuscole | permessi _ | vietate lettere maiuscole | obbligatorio primo carattere lettera | vietati caratteri speciali
        const result = regex.test(inputField);
        return result;
    }
}
