import { defineStore, acceptHMRUpdate } from 'pinia';

const getDefaultState = () => {
    return {
        viewName: null,
    };
};

export const useCurrentViewStorePinia = defineStore('currentView', {
    state: getDefaultState,
    getters: {
        getViewName: (state) => state.viewName,
    },
    actions: {
        setViewName(viewName) {
            this.viewName = viewName;
        },
    },
    persist: true,
});

if (module.hot) {
    module.hot.accept(
        acceptHMRUpdate(useCurrentViewStorePinia, module.hot)
    );
}
