function enumToSelectOptions(codes, labels, from, to) {
    const options = [];
    for (let i = 0; i < codes.length; i++) {
        const code = codes[i];
        const label = labels[i];
        if (from && code < from) continue;
        if (to && code >= to) continue;
        options.push({ value: code, text: label });
    }
    return options;
}

function enumCodeToLabel(codes, labels, code) {
    const index = codes.findIndex((c) => c === code);
    return index !== -1 ? labels[index] : 'ERROR - TYPE NOT FOUND';
}

class BaseEnum {
    static toSelectOptions(from, to) {
        return enumToSelectOptions(this.Codes, this.Labels, from, to);
    }

    static codeToLabel(code) {
        return enumCodeToLabel(this.Codes, this.Labels, code);
    }
}

export class Status extends BaseEnum {
    static SUSPENDED = 1;
    static ENTITLED = 2;

    static Codes = [Status.SUSPENDED, Status.ENTITLED];
    static Labels = ['Suspended', 'Entitled'];
}

export class Role extends BaseEnum {
    static USER = 1;
    static ADMIN = 2;
    static ROOT = 3;

    static Codes = [Role.USER, Role.ADMIN, Role.ROOT];
    static Labels = ['User', 'Admin', 'Root'];
}

export class Type extends BaseEnum {
    static INTEREST_RATE = 1;
    static CREDIT_SPREAD = 2;
    static DEPOSIT_COUNT = 3;
    static DEPOSIT_UNIT_VALUE = 4;
    static DEPOSIT_RATE = 5;
    static CUSTOM = 6;

    static Codes = [
        Type.INTEREST_RATE,
        Type.CREDIT_SPREAD,
        Type.DEPOSIT_COUNT,
        Type.DEPOSIT_UNIT_VALUE,
        Type.DEPOSIT_RATE,
        Type.CUSTOM,
    ];

    static Labels = [
        'Interest rate',
        'Credit spread',
        'Deposit count',
        'Deposit unit value',
        'Deposit rate',
        'Custom',
    ];
}

export class ScenGenParametrizationType extends BaseEnum {
    static CIR = 1;
    static VASICEK = 2;

    static Codes = [
        ScenGenParametrizationType.CIR,
        ScenGenParametrizationType.VASICEK,
    ];

    static Labels = ['CIR', 'Vasicek'];
}

export class ProcessScale extends BaseEnum {
    static LINEAR = 1;
    static LOGARITHMIC = 2;

    static Codes = [ProcessScale.LINEAR, ProcessScale.LOGARITHMIC];
    static Labels = ['Linear', 'Logarithmic'];
}

export class Duration extends BaseEnum {
    static Codes = Array.from({ length: 30 }, (_, i) => i + 1);
    static Labels = Duration.Codes.map((x) => `${x}Y`);
}

export class JobType extends BaseEnum {
    static ECONOMIC_REPORT = 1;
    static BETA_REPORT = 2;
    static BACKTESTING_REPORT = 3;

    static Codes = [
        JobType.ECONOMIC_REPORT,
        JobType.BETA_REPORT,
        JobType.BACKTESTING_REPORT,
    ];

    static Labels = [
        'Economic report',
        'Beta report',
        'Backtesting report',
    ];
}

export class JobStatus extends BaseEnum {
    static NEW = 1;
    static STARTED = 2;
    static COMPLETED = 3;
    static FAILED = 4;

    static Codes = [
        JobStatus.NEW,
        JobStatus.STARTED,
        JobStatus.COMPLETED,
        JobStatus.FAILED,
    ];

    static Labels = ['New', 'Started', 'Completed', 'Failed'];
}

export class DepositModelType extends BaseEnum {
    static LIABILITY = 1;
    static ASSET = 2;

    static Codes = [DepositModelType.LIABILITY, DepositModelType.ASSET];
    static Labels = ['Liability', 'Asset'];
}

export class TimeFrequency extends BaseEnum {
    static MONTHLY = 12;
    static QUARTERLY = 4;
    static SEMIANNUALLY = 2;
    static ANNUALLY = 1;

    static Codes = [
        TimeFrequency.MONTHLY,
        TimeFrequency.QUARTERLY,
        TimeFrequency.SEMIANNUALLY,
        TimeFrequency.ANNUALLY,
    ];

    static Labels = [
        'Monthly',
        'Quarterly',
        'Semiannually',
        'Annually',
    ];
}

export class RestrictedTimeFrequency extends BaseEnum {
    static MONTHLY = 12;
    static Codes = [RestrictedTimeFrequency.MONTHLY];
    static Labels = ['Monthly'];
}

export class OptionalModule extends BaseEnum {
    static PARAMETRIC_FREQUENCY = 1;
    static BETA_SCALING = 2;

    static Codes = [
        OptionalModule.PARAMETRIC_FREQUENCY,
        OptionalModule.BETA_SCALING,
    ];

    static Labels = ['PARAMETRIC_FREQUENCY', 'BETA_SCALING'];
}

export class ProcessName extends BaseEnum {
    static R = 'r';
    static I = 'i';
    static S = 's';
    static AT_V = '@v';
    static V = 'v';
    static X = 'x';
    static N = 'n';

    static Codes = [
        ProcessName.R,
        ProcessName.I,
        ProcessName.S,
        ProcessName.AT_V,
        ProcessName.V,
        ProcessName.X,
        ProcessName.N,
    ];

    static Labels = [
        'Market Interest Rate',
        'Deposit Rate',
        'Credit Spread',
        'Deposit Volume log var.',
        'Deposit Volume',
        'X',
        'N',
    ];
}
export class BetaType extends BaseEnum {
    static ECONOMETRIC = 1;
    static MARKET = 2;

    static Codes = [BetaType.ECONOMETRIC, BetaType.MARKET];
    static Labels = ['Econometric', 'Market'];
}
