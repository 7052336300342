import Cookie from '@/support/cookie';
import Router from '@/router';
import { useUserLoginStorePinia } from '@/store/userLoginStorePinia.js';

export default class Utils {
    static handle_401(err) {
        if (err.response && err.response.status === 401) Utils.logout();
    }

    static mapError(err) {
        let text = 'Error occurred, please try later.';
        let callback = () => {};
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    text = err.response.data.message;
                    break;
                case 401:
                    text =
                        'You are not authorized for the requested operation, please do login again.';
                    callback = () => Utils.logout();
                    break;
                case 403:
                    text =
                        "You don't have permissions for the requested operation, please contact the administrator.";
                    break;
                case 404:
                    text =
                        'Resource not found, please contact the administrator.';
                    break;
                case 413:
                    text =
                        'Payload too large, please contact the administrator.';
                    break;
                default:
                    text =
                        'Error occurred, please try later. Contact the administrator if the problem persists.';
            }
        }
        const payload = {
            title: 'Error',
            text,
            callback,
        };
        return payload;
    }

    static throwError(message) {
        throw new Error(message);
        // throw {
        //     response: { status: 400, data: { message } },
        // };
    }

    static isBlank(str) {
        return !str || str.trim().length === 0;
    }

    static isObject(obj) {
        return (
            Object.prototype.toString.call(obj) === '[object Object]'
        );
    }

    static isString(x) {
        return Object.prototype.toString.call(x) === '[object String]';
    }

    static isNull(obj) {
        if (obj === undefined || obj === null) return true;
        return !!(Utils.isString(obj) && obj.trim().length === 0);
    }

    static clone(src) {
        const obj = {};
        for (const k in src) {
            obj[k] = src[k];
        }
        return obj;
    }

    static cloneArray(src) {
        const array = [];

        for (const obj of src) {
            array.push(Utils.clone(obj));
        }

        return array;
    }

    static reload() {
        window.location.reload(true);
    }

    static setLocalSessionToken() {
        const url = new URL(window.location.href);
        const params = url.searchParams;

        let tkn = params.get('sid');
        if (!tkn) {
            tkn = Date.now().toString();
            params.set('sid', tkn);
            window.history.replaceState({}, '', url.toString());
        }
        return tkn;
    }

    static preserveLocalToken(localToken) {
        const url = new URL(window.location.href);
        const params = url.searchParams;

        // Check if 'sid' parameter is already set
        if (!params.has('sid')) {
            params.set('sid', localToken);
            window.history.pushState({}, '', url.toString());
        }
    }

    static logout() {
        console.log('static logout(), logging out');
        const userLoginStore = useUserLoginStorePinia();
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const sid = params.get('sid');
        let json = Cookie.read('tokenList');
        let tokenList = JSON.parse(json);
        if (tokenList) {
            tokenList = tokenList?.filter((x) => x.sid !== sid);
            json = JSON.stringify(tokenList);
            Cookie.create('tokenList', json);
        }
        userLoginStore.setDefaultState();
        Router.push({ name: 'LoginViewComp' });
    }

    static saveSessionToken(sid, token, username) {
        const obj = { sid, token, username };
        const tokenListJson = Cookie.read('tokenList');
        if (tokenListJson) {
            const tokenList = JSON.parse(tokenListJson);
            while (tokenList.length >= 5) tokenList.shift();
            tokenList.push(obj);
            Cookie.create('tokenList', JSON.stringify(tokenList));
        } else {
            const tokenList = [obj];
            Cookie.create('tokenList', JSON.stringify(tokenList));
        }
    }

    static loadSessionToken(sid) {
        const tokenListJson = Cookie.read('tokenList');
        if (!tokenListJson) return undefined;
        const tokenList = JSON.parse(tokenListJson).filter(
            (x) => x.sid === sid
        );
        return tokenList.length > 0
            ? tokenList[tokenList.length - 1]
            : undefined;
    }

    static compareYMDDates(dateString1, dateString2) {
        const dateInt1 =
            parseInt(dateString1.substring(0, 4)) * 10000 +
            parseInt(dateString1.substring(5, 7)) * 100 +
            parseInt(dateString1.substring(8));
        const dateInt2 =
            parseInt(dateString2.substring(0, 4)) * 10000 +
            parseInt(dateString2.substring(5, 7)) * 100 +
            parseInt(dateString2.substring(8));
        return dateInt1 - dateInt2;
    }

    static intToDate(int) {
        const s = int.toString();

        return (
            s.substring(0, 4) +
            '-' +
            s.substring(4, 6) +
            '-' +
            s.substring(6, 8)
        );
    }

    static dateToInt(date) {
        return (
            parseInt(date.substring(0, 4)) * 10000 +
            parseInt(date.substring(5, 7)) * 100 +
            parseInt(date.substring(8, 10))
        );
    }

    static parsePercentile(str) {
        if (
            typeof str !== 'string' ||
            str.endsWith('.') ||
            !str.length
        ) {
            return undefined;
        }

        const isPercentage = str.endsWith('%');
        const numericPart = isPercentage ? str.slice(0, -1) : str;

        if (isNaN(numericPart) || isNaN(parseFloat(numericPart))) {
            return undefined;
        }

        const value =
            parseFloat(numericPart) * (isPercentage ? 0.01 : 1);
        return value > 0 && value < 1 ? value : undefined;
    }

    static parsePercentage(str) {
        if (typeof str !== 'string') return undefined;
        if (str.length === 0) return 0.0;
        const hasPercentage = str.endsWith('%');
        if (hasPercentage) str = str.substring(0, str.length - 1);
        const num = parseFloat(str) / 100;
        return num;
    }

    static trimMilliseconds(timestamp) {
        return timestamp.split('.')[0];
    }

    static downloadJsonFile(obj, name) {
        const json = JSON.stringify(obj);
        const blob = new Blob([json], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        link.remove();
    }

    static downloadFile(url, name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
        link.remove();
    }

    static percentileFormatter(percentile) {
        const formattedPercentile = percentile * 100 + '%';
        return formattedPercentile;
    }

    static formatTwoDecimals(value) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(value);
    }
}
