<template>
    <div id="app">
        <TheNavbar
            @show-market-data-dialog="showImportMarketDataDialog()"
            @show-deposit-model-dialog="showImportDepositModelsDialog()"
            @export-market-data="exportMarketData()"
            @export-models="exportModels()"
            @logout="logout()"
        />
        <div class="app-body">
            <router-view />
        </div>
        <standard-dialog />
        <JsonImportDialog
            ref="import-market-data-dialog"
            :callback="importMarketData"
        />
        <JsonImportDialog
            ref="import-deposit-models-dialog"
            :callback="importDepositModels"
        />
        <DataDialog
            :ref="mdFileSchema.id"
            :spinner="true"
            :schema="mdFileSchema"
        />
        <DataDialog
            :ref="dmFileSchema.id"
            :spinner="true"
            :schema="dmFileSchema"
        />
    </div>
</template>

<script>
    import Axios from 'axios';
    import { mapState } from 'pinia';
    import { useUserLoginStorePinia } from '@/store/userLoginStorePinia.js';
    import TheNavbar from '@/components/TheNavbar.vue';
    import DataDialog from '@/components/dialogs/DataDialog.vue';
    import JsonImportDialog from '@/components/dialogs/JsonImportDialog.vue';
    import StandardDialog from '@/components/dialogs/StandardDialog';
    import { FileDialog } from '@/components/dialogs/schemas';
    import { useDialogStore } from '@/store/uiDialogStorePinia.js';
    import Utils from '@/support/utils';

    export default {
        name: 'NmdmApp',
        components: {
            JsonImportDialog,
            StandardDialog,
            DataDialog,
            TheNavbar,
        },
        data() {
            const dialogStorePinia = useDialogStore();
            return {
                dialogStorePinia,
            };
        },
        computed: {
            ...mapState(useUserLoginStorePinia, {
                authorization: (state) => state.authorization,
                companyId: (state) => state.companyId,
            }),
            mdFileSchema: function () {
                return FileDialog.getSchema(
                    this.importMarketData,
                    'md-file-dialog'
                );
            },
            dmFileSchema: function () {
                return FileDialog.getSchema(
                    this.importDepositModels,
                    'dm-file-dialog'
                );
            },
        },
        methods: {
            logout() {
                Utils.logout();
            },
            showImportMarketDataDialog() {
                this.$refs['import-market-data-dialog'].show();
            },
            async importMarketData(file) {
                const authorization = this.authorization;
                try {
                    const text = await this.readFile(file);
                    const json = JSON.parse(text);
                    await Axios.post(
                        'v1/market-data/import-from-json',
                        json,
                        authorization
                    );
                    this.dialogStorePinia.showDialog({
                        title: 'Successful import',
                        icon: 'check',
                        text: 'Market data have been imported.',
                        buttons: ['Ok'],
                        show: true,
                    });
                } catch (ex) {
                    this.dialogStorePinia.showErrorDialog(
                        Utils.mapError(ex)
                    );
                }
            },
            showImportDepositModelsDialog() {
                this.$refs['import-deposit-models-dialog'].show();
            },
            async importDepositModels(file) {
                const authorization = this.authorization;
                try {
                    const text = await this.readFile(file);
                    const json = JSON.parse(text);
                    await Axios.post(
                        'v1/banking-book/models/import-from-json',
                        json,
                        authorization
                    );
                    this.dialogStorePinia.showDialog({
                        title: 'Successful import',
                        icon: 'check',
                        text: 'Deposit models have been imported.',
                        buttons: ['Ok'],
                        show: true,
                    });
                } catch (ex) {
                    this.dialogStorePinia.showErrorDialog(
                        Utils.mapError(ex)
                    );
                }
            },
            readFile(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsText(file);
                });
            },
            async exportMarketData() {
                try {
                    const response = await Axios.get(
                        `v1/market-data/${this.companyId}/export`,
                        this.authorization
                    );
                    Utils.downloadJsonFile(
                        response.data,
                        'MarketData.json'
                    );
                } catch (ex) {
                    this.dialogStorePinia.showErrorDialog(
                        Utils.mapError(ex)
                    );
                }
            },
            async exportModels() {
                try {
                    const response = await Axios.get(
                        `v1/banking-book/${this.companyId}/export`,
                        this.authorization
                    );
                    Utils.downloadJsonFile(
                        response.data,
                        'DepositModels.json'
                    );
                } catch (ex) {
                    this.dialogStorePinia.showErrorDialog(
                        Utils.mapError(ex)
                    );
                }
            },
        },
    };
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');
    #app {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    .navbar-dark .navbar-nav .navbar-text {
        margin-left: 15px;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .navbar-text,
    .navbar-dark .navbar-nav .nav-link svg {
        color: rgba(255, 255, 255, 0.7);
        fill: rgba(255, 255, 255, 0.7) !important;
    }
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link svg:hover {
        color: rgba(255, 255, 255, 0.9);
        fill: rgba(255, 255, 255, 0.9) !important;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .modal-dialog .modal-content#file-dialog___BV_modal_content_ {
        min-height: unset;
        height: auto;
    }

    .modal-dialog
        .modal-content#file-dialog___BV_modal_content_
        #file-dialog___BV_modal_header_ {
        display: none;
    }

    #file-dialog.modal .modal-dialog.modal-md {
        top: 0%;
        transform: translate(0%, 0%) !important;
    }

    @media (min-width: 992px) {
        .modal .modal-huge {
            max-width: 90% !important;
            width: 90% !important;
        }
    }
</style>
