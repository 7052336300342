<template>
    <b-modal
        v-model="showModal"
        :busy="showSpinner ? true : false"
        no-close-on-backdrop
        title="Import"
        @ok="ok"
        @cancel="reset"
        @close="reset"
    >
        <template #modal-header="{ close }">
            <h5>Modal Header</h5>
            <b-icon
                v-if="!showSpinner"
                icon="x"
                class="h2"
                style="cursor: pointer"
                variant="dark"
                @click="close"
            />
            <b-icon v-else icon="x" class="h2" variant="secondary" />
        </template>
        <div class="d-flex align-items-center">
            <b-form>
                <b-form-group label="File">
                    <b-form-file v-model="file" :state="validFile" />
                </b-form-group>
            </b-form>
            <b-spinner v-if="showSpinner" class="ml-3" />
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'JsonImportDialog',
        props: {
            callback: {
                type: Function,
                required: true,
            },
        },
        data() {
            return {
                showModal: false,
                file: undefined,
                firstDraft: true,
                showSpinner: false,
            };
        },
        computed: {
            validFile() {
                if (this.firstDraft === true) return undefined;
                return this.file !== undefined;
            },
        },
        methods: {
            show() {
                this.showModal = true;
            },
            async ok(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.firstDraft = false;
                if (this.isFileValid()) {
                    this.showSpinner = true;
                    await this.import(this.file);
                    this.showSpinner = false;
                    this.reset();
                    this.showModal = false;
                }
            },
            reset() {
                this.file = undefined;
                this.firstDraft = true;
            },
            isFileValid() {
                return this.file !== undefined;
            },
            async import(file) {
                await this.callback(file);
            },
        },
    };
</script>
