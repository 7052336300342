import {
    DepositModelType,
    Status,
    Role,
    Type,
    ProcessScale,
} from '@/enums';
import Validators from '@/support/validators';

export class UserDialog {
    static SchemaId = 'user-dialog';

    static getSchema(isRoot, save) {
        return {
            title: 'User',
            id: UserDialog.SchemaId,
            fields: [
                {
                    id: 'firstName',
                    label: 'First name',
                    validator: Validators.onlyLetters,
                    charPermittedMessage:
                        'Valid characters are a-z | A-Z',
                },
                {
                    id: 'lastName',
                    label: 'Last name',
                    validator: Validators.onlyLetters,
                    charPermittedMessage:
                        'Valid characters are a-z | A-Z',
                },
                {
                    id: 'email',
                    label: 'Email',
                    validator: Validators.email,
                    charPermittedMessage: 'Insert valid email',
                },
                {
                    id: 'role',
                    label: 'Role',
                    type: 'select',
                    options: Role.toSelectOptions(
                        Role.USER,
                        isRoot ? undefined : Role.ROOT
                    ),
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'select',
                    options: Status.toSelectOptions(),
                },
            ],
            ok_event: 'user-update',
            save,
        };
    }
}

export class CompanyDialog {
    static SchemaId = 'company-dialog';

    static getSchema(save) {
        return {
            title: 'Company',
            id: CompanyDialog.SchemaId,
            fields: [
                {
                    id: 'businessName',
                    label: 'Business name',
                    validator: Validators.lettersNumbersAndSpaces,
                    charPermittedMessage:
                        'Valid characters are a-z | A-Z | 0-9',
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'select',
                    options: Status.toSelectOptions(),
                },
                {
                    id: 'licenseExpirationDate',
                    label: 'License expiration date',
                    type: 'date',
                },
                {
                    id: 'parametricFrequency',
                    label: 'Parametric frequency',
                    type: 'select',
                    options: ['enabled', 'disabled'],
                },
                {
                    id: 'betaScaling',
                    label: 'Beta scaling',
                    type: 'select',
                    options: ['enabled', 'disabled'],
                },
            ],
            ok_event: 'company-update',
            save,
        };
    }
}

export class CurveDialog {
    static SchemaId = 'curve-dialog';

    static getSchema(save) {
        return {
            title: 'Curve',
            id: CurveDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    validator:
                        Validators.marketDataAndDepositModelsNames,
                    charPermittedMessage:
                        'Valid characters (max 64) are a-z | A-Z | 0-9 | _ | . | - ',
                },
            ],
            ok_event: 'curve-update',
            save,
        };
    }
}

export class AsOfDateDialog {
    static SchemaId = 'as-of-date-dialog';

    static getSchema(save) {
        return {
            title: 'As of date',
            id: AsOfDateDialog.SchemaId,
            fields: [
                { id: 'asOfDate', label: 'As of date', type: 'date' },
            ],
            ok_event: 'as-of-date-update',
            save,
        };
    }
}

export class PillarDialog {
    static SchemaId = 'pillar-dialog';

    static getSchema(save) {
        return {
            title: 'Pillar',
            id: PillarDialog.SchemaId,
            fields: [
                { id: 'offset', label: 'Offset', type: 'number' },
                { id: 'rate', label: 'Rate', type: 'number' },
            ],
            ok_event: 'pillar-update',
            save,
        };
    }
}

export class FileDialog {
    static SchemaId = 'file-dialog';

    static getSchema(save, schemaId) {
        return {
            title: 'File',
            id: schemaId || FileDialog.SchemaId,
            fields: [{ id: 'file', label: 'File', type: 'file' }],
            ok_event: 'file-update',
            save,
        };
    }
}

export class TimeSeriesDialog {
    static SchemaId = 'time-series-dialog';

    static getSchema(save) {
        return {
            title: 'Time series',
            id: CurveDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    validator:
                        Validators.marketDataAndDepositModelsNames,
                    charPermittedMessage:
                        'Valid characters (max 64) are a-z | A-Z | 0-9 | _ | . | - ',
                },
                {
                    id: 'type',
                    label: 'Type',
                    type: 'select',
                    options: Type.toSelectOptions(),
                },
            ],
            ok_event: 'time-series-update',
            save,
        };
    }
}

export class TimeSeriesPillarDialog {
    static SchemaId = 'time-series-pillar-dialog';

    static getSchema(save) {
        return {
            title: 'Pillar',
            id: TimeSeriesPillarDialog.SchemaId,
            fields: [
                { id: 'date', label: 'Date', type: 'date' },
                { id: 'value', label: 'Value', type: 'number' },
            ],
            ok_event: 'time-series-pillar-update',
            save,
        };
    }
}

export class ScenGenParametrizationNameDialog {
    static SchemaId = 'scen-gen-parametrization-name-dialog';

    static getSchema(save) {
        return {
            title: 'Name',
            id: ScenGenParametrizationNameDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                    validator:
                        Validators.marketDataAndDepositModelsNames,
                    charPermittedMessage:
                        'Valid characters (max 64) are a-z | A-Z | 0-9 | _ | . | - ',
                },
            ],
            ok_event: 'scen-gen-parametrization-name-update',
            save,
        };
    }
}

export class ScenGenParametrizationAsOfDateDialog {
    static SchemaId = 'scen-gen-parametrization-as-of-date-dialog';

    static getSchema(save) {
        return {
            title: 'As of date',
            id: ScenGenParametrizationAsOfDateDialog.SchemaId,
            fields: [
                { id: 'asOfDate', label: 'As of date', type: 'date' },
            ],
            ok_event: 'scen-gen-parametrization-as-of-date-update',
            save,
        };
    }
}

export class ModelDialog {
    static SchemaId = 'model-dialog';

    static getSchema(save) {
        return {
            title: 'Model',
            id: ModelDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                    validator:
                        Validators.marketDataAndDepositModelsNames,
                    charPermittedMessage:
                        'Valid characters (max 64) are a-z | A-Z | 0-9 | _ | . | - ',
                },
                {
                    id: 'type',
                    label: 'Type',
                    type: 'select',
                    options: DepositModelType.toSelectOptions(),
                },
            ],
            ok_event: 'model-update',
            save,
        };
    }
}

export class InstanceDateDialog {
    static SchemaId = 'instance-date-dialog';

    static getSchema(save) {
        return {
            title: 'Model instance',
            id: InstanceDateDialog.SchemaId,
            fields: [{ id: 'date', label: 'Date', type: 'date' }],
            ok_event: 'instance-date-update',
            save,
        };
    }
}

export class ProcessDialog {
    static SchemaId = 'process-dialog';

    static getSchema(
        save,
        timeSeries,
        addOrEditProcess,
        selectedProcess
    ) {
        const purposeChange = (option) => {
            const field = schema.fields.filter(
                (x) => x.id === 'timeSeriesId'
            )[0];
            field.clear();
            field.options = schema.timeSeries
                .filter((x) => x.type === option)
                .map((x) => {
                    return { value: x.id, text: x.name };
                });
            schema.fields = schema.fields.map((x) => x);
            schema.forceUpdate();
        };
        const schema = {
            title: 'Type of process',
            id: addOrEditProcess,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                    validator: Validators.processesNames,
                    charPermittedMessage:
                        'Valid characters are a-z | 0-9 | _ ',
                },
                {
                    id: 'processScale',
                    label: 'Process scale',
                    type: 'select',
                    options: ProcessScale.toSelectOptions(),
                },
                {
                    id: 'averaging',
                    label: 'Averaging',
                    check: true,
                    type: 'number',
                },
                {
                    id: 'variation',
                    label: 'Variation',
                    check: true,
                    type: 'number',
                },
                { id: 'expression', label: 'Expression', type: 'text' },
                {
                    id: 'purpose',
                    label: 'Purpose',
                    type: 'select',
                    options: Type.toSelectOptions(3, 7),
                    onChange: purposeChange,
                },
                {
                    id: 'timeSeriesId',
                    label: 'Time series',
                    type: 'select',
                    options: '',
                },
                {
                    id: 'noiseIntensity',
                    label: 'Noise intensity',
                    check: true,
                    type: 'number',
                },
                {
                    id: 'noiseAutocorrelation',
                    label: 'Noise autocorrelation',
                    check: true,
                    type: 'number',
                },
            ],
            timeSeries,
            ok_event: 'process-update',
            save,
        };

        if (selectedProcess !== undefined) {
            const field = schema.fields.filter(
                (x) => x.id === 'timeSeriesId'
            )[0];

            field.options = schema.timeSeries
                .filter((x) => x.type === selectedProcess.purpose)
                .map((x) => {
                    return { value: x.id, text: x.name };
                });

            schema.fields = schema.fields.map((x) => x);
        }

        return schema;
    }
}

export class AnalysisNameDialog {
    static SchemaId = 'analysis-name-dialog';

    static getSchema(save) {
        return {
            title: 'Analysis',
            id: AnalysisNameDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    validator: Validators.reportsAndAnalysisNames,
                    charPermittedMessage:
                        'Valid characters (max 256) are a-z | A-Z | 0-9 | _ | . | - | @ | # | $ | % | & | + ',
                },
            ],
            ok_event: 'analysis-update',
            save,
        };
    }
}

/* PROVE MIE------------------------------- */
export class ReportDialog {
    static SchemaId = 'report-dialog';

    static getSchema(save) {
        return {
            title: 'Report',
            id: ReportDialog.SchemaId,
            fields: [
                {
                    id: 'name',
                    label: 'Name',
                    validator: Validators.reportsAndAnalysisNames,
                    charPermittedMessage:
                        'Valid characters (max 256) are a-z | A-Z | 0-9 | _ | . | - | @ | # | $ | % | & | + ',
                },
            ],
            ok_event: 'report-update',
            save,
        };
    }
}
