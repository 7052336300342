import { defineStore } from 'pinia';

export const useDialogStore = defineStore('dialog', {
    state: () => ({
        standardDialog: {
            show: false,
            title: null,
            icon: null,
            text: '',
            buttons: [],
            closeButton: null,
            cancelButton: null,
            callback: null,
            callbackPayload: null,
        },
        showSpinner: false,
    }),
    actions: {
        updateStandardDialog(dialogUpdates) {
            this.standardDialog = {
                ...this.standardDialog,
                ...dialogUpdates,
            };
        },
        showDialog(dialogData) {
            this.updateStandardDialog({ ...dialogData, show: true });
        },
        showErrorDialog({ title, text, callback }) {
            this.updateStandardDialog({
                title,
                icon: 'error',
                text,
                buttons: ['Ok'],
                closeButton: null,
                cancelButton: null,
                callback,
                show: true,
            });
        },
        showSuccessDialog({ title, text, callback }) {
            this.updateStandardDialog({
                title,
                icon: 'check',
                text,
                buttons: ['Ok'],
                closeButton: null,
                cancelButton: null,
                callback,
                show: true,
            });
        },
        showConfirmDialog({ title, text, callback }) {
            this.updateStandardDialog({
                title,
                icon: 'warning',
                text,
                buttons: ['Ok'],
                closeButton: null,
                cancelButton: true,
                callback,
                show: true,
            });
        },
        closeStandardDialog() {
            this.standardDialog.show = false;
        },
        toggleSpinner(show) {
            this.showSpinner = show;
        },
    },
});
