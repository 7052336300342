<template>
    <b-modal
        id="dialogId"
        :title="data.title"
        :hide-header-close="!data.closeButton"
        :header-bg-variant="iconColor"
        header-text-variant="light"
        no-close-on-backdrop
        no-close-on-esc
        @hidden="handleHidden"
    >
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
                <div>
                    <b-icon
                        :icon="iconShape"
                        :variant="iconColor"
                        font-scale="4"
                    />
                </div>
                <div class="ml-3">
                    {{ data.text }}
                </div>
            </div>
        </div>

        <template #modal-footer="{}">
            <b-button
                v-if="data.cancelButton"
                key="Cancel"
                class="btn float-right ml-2"
                variant="secondary"
                @click="handleCancel()"
            >
                Cancel
            </b-button>
            <b-button
                v-for="button in data.buttons"
                :key="button"
                class="btn float-right ml-2"
                variant="dark"
                @click="handleButtonClick(button)"
            >
                {{ button }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useDialogStore } from '@/store/uiDialogStorePinia';
    export default {
        computed: {
            ...mapState(useDialogStore, {
                data: (state) => state.standardDialog,
            }),

            showDialog() {
                return this.data.show;
            },
            iconShape() {
                if (this.data.icon === 'warning') {
                    return 'exclamation-triangle-fill';
                } else if (this.data.icon === 'error') {
                    return 'exclamation-circle-fill';
                } else if (this.data.icon === 'check') {
                    return 'check';
                } else {
                    return this.data.icon;
                }
            },
            iconColor() {
                if (this.data.icon === 'warning') {
                    return 'warning';
                } else if (this.data.icon === 'error') {
                    return 'danger';
                } else if (this.data.icon === 'check') {
                    return 'success';
                } else {
                    return 'dark';
                }
            },
        },
        watch: {
            showDialog(newValue) {
                if (newValue === true) {
                    this.$bvModal.show('dialogId');
                }
            },
        },
        methods: {
            ...mapActions(useDialogStore, {
                close: 'closeStandardDialog',
            }),

            handleHidden() {
                this.close();
            },
            handleButtonClick(label) {
                if (this.data.callback) {
                    if (this.data.callbackPayload) {
                        this.data.callback(
                            label,
                            this.data.callbackPayload
                        );
                    } else {
                        this.data.callback(label);
                    }
                }
                this.close();
                this.$bvModal.hide('dialogId');
            },
            handleCancel() {
                this.close();
                this.$bvModal.hide('dialogId');
            },
        },
    };
</script>

<style lang="css" scoped>
    .alert-modal {
        height: auto !important;
    }
</style>
