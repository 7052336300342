import Vue from 'vue';
import VueRouter from 'vue-router';
import { PiniaVuePlugin } from 'pinia';
import App from '@/App.vue';
import router from '@/router';
import pinia from '@/plugins/pinia';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueColumnsResizable from '@/plugins/vue-columns-resizable';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(PiniaVuePlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueColumnsResizable);

new Vue({
    router,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
