<template>
    <b-modal
        :id="schema.id"
        :ref="schema.id"
        :title="schema.title"
        data-keyboard="false"
        data-backdrop="static"
        :ok-disabled="modalOkState"
        @show="resetOkState()"
        @ok="handleOk"
        @cancel="handleCancel"
        @close="handleClose"
    >
        <div class="d-flex align-items-center">
            <b-form class="col-10" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    v-for="field in fields"
                    :id="field.id"
                    :key="field.id"
                    :label="field.label"
                    :label-for="field.label_for"
                >
                    <div class="d-flex align-items-center">
                        <div v-if="field.check">
                            <b-form-checkbox
                                :id="field.label_cb"
                                v-model="cbs[field.ordinal]"
                                @input="handleCheckBox($event, field)"
                            />
                        </div>
                        <div v-if="field.type === 'select'">
                            <b-form-select
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                :options="field.options"
                                required
                                @change="field.onChange"
                            />
                            <b-form-invalid-feedback>
                                Invalid select
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else-if="field.type === 'date'">
                            <b-datepicker
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                required
                            />
                            <b-form-invalid-feedback>
                                Invalid date
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else-if="field.type === 'file'">
                            <b-form-file
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                required
                            />
                            <b-form-invalid-feedback>
                                Invalid file
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else-if="field.type === 'number'">
                            <b-form-input
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                type="number"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                :required="cbs[field.ordinal]"
                            />
                            <b-form-invalid-feedback>
                                Invalid number
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else-if="field.type === 'currency'">
                            <b-form-input
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                v-ccy
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                :required="cbs[field.ordinal]"
                            />
                            <b-form-invalid-feedback>
                                Invalid currency
                            </b-form-invalid-feedback>
                        </div>
                        <div v-else-if="field.type === 'boolean'">
                            <b-form-checkbox
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                required
                            />
                        </div>
                        <div v-else>
                            <b-form-input
                                :id="field.label_for"
                                v-model="form[field.ordinal]"
                                :state="state[field.ordinal]"
                                :disabled="!enabled[field.ordinal]"
                                type="text"
                                required
                            />
                            <b-form-invalid-feedback>
                                Invalid characters detected
                            </b-form-invalid-feedback>
                            <b-form-text id="input-live-help">
                                {{ field.charPermittedMessage }}
                            </b-form-text>
                        </div>
                    </div>
                </b-form-group>
            </b-form>
            <b-spinner
                v-if="spinner === true && spinnerOn === true"
                variant="primary"
                label="Spinning"
            />
        </div>
    </b-modal>
</template>

<script>
    import Ccy from '@/support/ccy';
    import { CurrencyDirective } from '@/components/directives/currency-directive';
    import Utils from '@/support/utils';
    import { useDialogStore } from '@/store/uiDialogStorePinia.js';

    export default {
        name: 'DataDialog',
        directives: {
            CurrencyDirective,
        },
        props: {
            schema: {
                type: Object,
                required: true,
            },
            spinner: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            const dialogStore = useDialogStore();

            return {
                dialogStore,
                spinnerOn: false,
                form: this.schema.fields.map((x) => undefined),
                cbs: this.schema.fields.map((x) => false),
                state: this.schema.fields.map((x) => undefined),
                enabled: this.schema.fields.map((x) => !x.check),
                extra: {},

                modalOkState: true,
            };
        },
        computed: {
            fields() {
                const schemaCopy = JSON.parse(
                    JSON.stringify(this.schema)
                );
                let i = 0;
                for (const field of schemaCopy.fields) {
                    field.ordinal = i++;
                    field.label_for = `input-${field.id}`;
                    field.v_model = `form.${field.id}`;
                    field.label_cb = `cb-${field.id}`;
                    if (!field.type) field.type = 'text';
                    field.clear = () => {
                        this.form[field.ordinal] = '';
                    };
                }
                schemaCopy.forceUpdate = () => {
                    this.$forceUpdate();
                };
                return schemaCopy.fields;
            },
        },
        watch: {
            form(values) {
                const normalStates = ['date', 'file', 'select'];
                const NUMBER_FIELD_TYPE = 'number';
                this.state = values.map((value, idx) => {
                    const field = this.schema.fields[idx];
                    if (field.validator) {
                        return field.validator(value);
                    }

                    if (
                        normalStates.includes(field.type) &&
                        this.form[idx] !== undefined
                    ) {
                        return true;
                    }

                    if (field.type === NUMBER_FIELD_TYPE) {
                        return (
                            this.form[idx] !== undefined &&
                            this.form[idx] !== ''
                        );
                    }
                    return false;
                });
                this.modalOkState = !this.state.every(Boolean);
            },
        },
        async created() {
            if (this.schema.created) await this.schema.created();
        },
        methods: {
            show: function (obj) {
                if (obj) this.setData(obj);
                this.$refs[this.schema.id].show();
            },
            resetOkState: function () {
                this.modalOkState = true;
            },
            setData: function (obj) {
                if (obj) {
                    this.cbs = this.fields.map(
                        (field) =>
                            field.check !== undefined &&
                            obj[field.id] !== undefined
                    );
                    this.state = this.fields.map(
                        (field) =>
                            field.check === undefined ||
                            obj[field.id] !== undefined
                    );
                    this.enabled = this.fields.map(
                        (field) => !field.check || obj[field.id]
                    );
                    this.form = this.fields.map((field) =>
                        this.format(field.type, obj[field.id])
                    );
                    for (const k in obj) {
                        if (!this.fields.some((f) => f.id === k)) {
                            this.extra[k] = obj[k];
                        }
                    }
                } else {
                    this.resetModal();
                }
            },
            getData: function () {
                const obj = {};
                for (const field of this.fields) {
                    obj[field.id] = this.unformat(
                        field.type,
                        this.form[field.ordinal]
                    );
                }
                for (const k in this.extra) obj[k] = this.extra[k];
                return obj;
            },
            checkFormValidity: function () {
                const state = this.schema.fields.map((x) => true);
                for (const field of this.fields) {
                    const value = this.form[field.ordinal];
                    if (
                        field.optional === true &&
                        Utils.isNull(value)
                    ) {
                        state[field.ordinal] = true;
                    } else if (field.type === 'boolean') {
                        state[field.ordinal] = true;
                    } else {
                        // todo: introduce a field validator
                        state[field.ordinal] = !Utils.isNull(value);
                    }

                    if (
                        this.fields[field.ordinal].check &&
                        !this.cbs[field.ordinal]
                    ) {
                        state[field.ordinal] = undefined;
                    }
                }

                this.state = state;
                return this.state.every(
                    (x) => x === true || x === undefined
                );
            },

            resetModal() {
                this.form = this.schema.fields.map((x) => undefined);
                this.cbs = this.schema.fields.map((x) => false);
                this.state = this.schema.fields.map((x) => undefined);
                this.enabled = this.schema.fields.map((x) => !x.check);
                this.extra = {};
            },
            async handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault();
                // Trigger submit handler
                const ok = await this.handleSubmit();
                if (ok) {
                    this.$emit(this.schema.ok_event, this.getData());
                }
                this.spinnerOn = false;
            },
            handleCancel: async function (bvModalEvt) {
                this.resetModal();
            },
            handleClose: async function (bvModalEvt) {
                this.resetModal();
            },
            handleSubmit: async function () {
                // Exit when the form isn't valid
                const dataAreValid = this.checkFormValidity();
                if (!dataAreValid) return;
                // Push the name to submitted names
                const data = this.getData();
                if (this.spinner) {
                    this.spinnerOn = true;
                    const $this = this;
                    const promise = this.schema.save(data);
                    promise
                        .then(function () {
                            $this.spinnerOn = false;
                        })
                        .catch(function () {
                            console.log('error');
                        })
                        .finally(function () {
                            console.log('HO FINITO');
                        });
                } else {
                    try {
                        await this.schema.save(data);
                        setTimeout(() => this.resetModal(), 300);
                    } catch (ex) {
                        this.dialogStore.showErrorDialog(
                            Utils.mapError(ex)
                        );
                        return false;
                    }
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.schema.id);
                    });
                    return true;
                }
            },
            ordinalById: function (id) {
                return this.fields.filter((x) => x.id === id)[0]
                    .ordinal;
            },
            handleCheckBox: function (checked, field) {
                if (!checked) {
                    this.form[field.ordinal] = undefined;
                    this.form = this.form.map((x) => x);
                }
                this.cbs[field.ordinal] = checked;
                this.enabled[field.ordinal] = checked;
                this.enabled = this.enabled.map((x) => x);
            },

            getDataById: function (id) {
                const i = this.ordinalById(id);
                return this.unformat(this.fields[i].type, this.form[i]);
            },
            setDataById: function (id, data) {
                const i = this.ordinalById(id);
                this.form[i] = this.format(this.fields[i].type, data);
                this.form = this.form.map((x) => x);
            },
            format: function (type, val) {
                if (type === 'currency') return Ccy.format(val);
                else return val;
            },
            unformat: function (type, val) {
                if (type === 'currency') return Ccy.unformat(val);
                else return val;
            },
        },
    };
</script>

<style lang="css" scoped>
    /* ANTI SCROLLING MODAL BLOCK */
    .modal {
        overflow: hidden !important;
    }
    /* PICK DATE DIALOG CENTRATA */
    .modal-dialog {
        transform: translate(0%, -50%) !important;
    }
    /* CENTRATURA MODALE */
    .modal .modal-dialog.modal-md {
        top: 50%;
        transform: translate(0%, -55%) !important;
    }

    /* PICK DATE DIALOG ALLUNGATA */
    .modal-dialog .modal-content {
        /* min-height: 640px; */
        min-height: 580px;
        max-height: 90vh;
    }

    /* OVERFLOW DEL BODY MODALE */
    .modal-dialog .modal-content .modal-body {
        overflow: auto !important;
    }
</style>
