const routes = [
    {
        path: '/deposit-models',
        name: 'DepositModels',
        component: () =>
            import(
                /* webpackChunkName: "models" */ '../views/DepositModels.vue'
            ),
        meta: {
            viewName: 'Deposit Models',
        },
    },
    {
        path: '/scengen-parametrizations',
        name: 'ScenGenParametrizations',
        component: () =>
            import(
                /* webpackChunkName: "scenGenParametrizations" */ '../views/ScenGenParametrizations.vue'
            ),
        meta: {
            viewName: 'Scen gen Parametrization',
        },
    },
    {
        path: '/shock-parametrizations-presets',
        name: 'ShockParametrizationsPresets',
        component: () =>
            import(
                /* webpackChunkName: "ShockParametrizationsPresets" */ '../views/ShockParametrizationsPresets.vue'
            ),
        meta: {
            viewName: 'Shocks Parametrization',
        },
    },
    {
        path: '/interest-rate-curves',
        name: 'InterestRateCurves',
        component: () =>
            import(
                /* webpackChunkName: "interestRateCurves" */ '../views/InterestRateCurves.vue'
            ),
        meta: {
            viewName: 'Interest Rate Curves',
        },
    },
    {
        path: '/time-series',
        name: 'TimeSeries',
        component: () =>
            import(
                /* webpackChunkName: "timeSeries" */ '../views/TimeSeries.vue'
            ),
        meta: {
            viewName: 'Time Series',
        },
    },
    {
        path: '/login',
        name: 'LoginViewComp',
        component: () =>
            import(
                /* webpackChunkName: "login" */ '../views/LoginView.vue'
            ),
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/HomeView.vue'
            ),
    },
    {
        path: '/companies',
        name: 'Companies',
        component: () =>
            import(
                /* webpackChunkName: "companies" */ '../views/CompaniesView.vue'
            ),
        meta: {
            viewName: 'Companies',
        },
    },
    {
        path: '/users',
        name: 'Users',
        component: () =>
            import(
                /* webpackChunkName: "users" */ '../views/UsersView.vue'
            ),
        meta: {
            viewName: 'Users',
        },
    },
    {
        path: '/index.html',
        redirect: '/login',
    },
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/economic-analysis',
        name: 'EconomicAnalysis',
        component: () =>
            import(
                /* webpackChunkName: "economicAnalysis" */ '../views/EconomicAnalysis.vue'
            ),
        meta: {
            viewName: 'Economic Analysis',
        },
    },
    {
        path: '/economic-reports',
        name: 'EconomicReports',
        component: () =>
            import(
                /* webpackChunkName: "economicReports" */ '../views/EconomicReports.vue'
            ),
        meta: {
            viewName: 'Economic Reports',
        },
    },
    {
        path: '/allocation-analysis',
        name: 'AllocationAnalysis',
        component: () =>
            import(
                /* webpackChunkName: "allocationAnalysis" */ '../views/AllocationAnalysis.vue'
            ),
        meta: {
            viewName: 'Allocation Analysis',
        },
    },
    {
        path: '/allocation-reports',
        name: 'AllocationReports',
        component: () =>
            import(
                /* webpackChunkName: "allocationReports" */ '../views/AllocationReports.vue'
            ),
        meta: {
            viewName: 'Allocation Reports',
        },
    },
    {
        path: '/backtesting-analysis',
        name: 'BacktestingAnalysis',
        component: () =>
            import(
                /* webpackChunkName: "backtestingAnalysis" */ '../views/BacktestingAnalysis.vue'
            ),
        meta: {
            viewName: 'Backtesting Analysis',
        },
    },
    {
        path: '/backtesting-reports',
        name: 'BacktestingReports',
        component: () =>
            import(
                /* webpackChunkName: "backtestingReports" */ '../views/BacktestingReports.vue'
            ),
        meta: {
            viewName: 'Backtesting Reports',
        },
    },
];

export default routes;
