export default class Cookie {
    static create(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toGMTString();
        }
        document.cookie =
            name +
            '=' +
            value +
            expires +
            '; path=/' +
            '; SameSite=Strict';
    }

    static read(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (const element of ca) {
            let c = element;
            while (c.startsWith(' ')) c = c.substring(1, c.length);
            if (c.startsWith(nameEQ))
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static erase(name) {
        Cookie.createCookie(name, '', -1);
    }
}
