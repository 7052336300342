import VueRouter from 'vue-router';
import routes from './routes';
import pinia from '@/plugins/pinia';
import { useCurrentViewStorePinia } from '../store/currentViewStorePinia.js';
import { useUserLoginStorePinia } from '@/store/userLoginStorePinia.js';

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const { viewName } = to.meta;
    const payload = viewName || null;
    const userLoginStore = useUserLoginStorePinia(pinia);
    const currentViewStore = useCurrentViewStorePinia(pinia);
    userLoginStore.setLocalSessionToken();
    currentViewStore.setViewName(payload);
    next();
});

router.afterEach((to, from) => {
    const userLoginStore = useUserLoginStorePinia(pinia);
    userLoginStore.preserveLocalToken();
});

export default router;
