const leftNavbarLink = [
    {
        title: 'Market Data',
        items: [
            {
                title: 'Interest rate curves',
                link: { name: 'InterestRateCurves' },
            },
            { title: 'Time series', link: { name: 'TimeSeries' } },
            {
                title: 'Scen gen Parametrization',
                link: { name: 'ScenGenParametrizations' },
            },
            {
                title: 'Shocks Parametrization',
                link: {
                    name: 'ShockParametrizationsPresets',
                },
            },
        ],
    },
    {
        title: 'Deposit Models',
        link: { name: 'DepositModels' },
    },
    {
        title: 'Analysis',
        items: [
            {
                title: 'Economic analysis',
                link: { name: 'EconomicAnalysis' },
            },
            {
                title: 'Allocation analysis',
                link: { name: 'AllocationAnalysis' },
            },
            {
                title: 'Backtesting analysis',
                link: { name: 'BacktestingAnalysis' },
            },
        ],
    },
    {
        title: 'Reports',
        items: [
            {
                title: 'Economic reports',
                link: { name: 'EconomicReports' },
            },
            {
                title: 'Allocation reports',
                link: { name: 'AllocationReports' },
            },
            {
                title: 'Backtesting reports',
                link: { name: 'BacktestingReports' },
            },
        ],
    },
];

export { leftNavbarLink };
