<script>
    import { computed } from 'vue';
    import { Icon } from '@iconify/vue2';
    import { leftNavbarLink } from '@/schemas/navbar-links.js';

    import { useCurrentViewStorePinia } from '@/store/currentViewStorePinia.js';
    import { useUserLoginStorePinia } from '@/store/userLoginStorePinia.js';
    export default {
        name: 'TheNavbar',
        components: {
            Icon,
        },
        emits: [
            'logout',
            'show-market-data-dialog',
            'show-deposit-model-dialog',
            'export-market-data',
            'export-models',
        ],
        setup(props, { emit }) {
            const userLoginStore = useUserLoginStorePinia();
            const currentViewStore = useCurrentViewStorePinia();
            const superUser = computed(
                () =>
                    userLoginStore.userIsRoot ||
                    userLoginStore.userIsAdmin
            );

            const showDataExport = computed(
                () =>
                    userLoginStore.userIsLogged &&
                    !userLoginStore.userIsRoot
            );
            const leftNavbarLinks = computed(() => {
                return leftNavbarLink;
            });

            return {
                leftNavbarLinks,
                currentViewStore,
                userLoginStore,
                superUser,
                showDataExport,
                // Emits can be accessed via context in setup
                emit,
            };
        },
    };
</script>

<template>
    <b-navbar
        v-if="userLoginStore.userIsLogged"
        type="dark"
        style="background-color: #303956"
    >
        <b-col cols="5" class="p-0 d-flex align-items-center">
            <b-navbar-brand href="#">
                <img
                    alt="Iason logo"
                    class="icon-logo"
                    src="@/assets/logo.png"
                />
            </b-navbar-brand>

            <b-navbar-nav style="font-weight: bold">
                <div
                    v-for="(navbarItem, index) in leftNavbarLinks"
                    :key="index"
                >
                    <div v-if="navbarItem.items">
                        <b-nav-item-dropdown>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                {{ navbarItem.title }}
                            </template>
                            <b-dropdown-item
                                v-for="(
                                    subItem, jndex
                                ) in navbarItem.items"
                                :key="jndex"
                                :to="subItem.link"
                            >
                                {{ subItem.title }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </div>
                    <div v-else>
                        <b-nav-item :to="navbarItem.link">
                            {{ navbarItem.title }}
                        </b-nav-item>
                    </div>
                </div>
            </b-navbar-nav>
        </b-col>
        <b-col
            cols="2"
            class="p-0 d-flex align-items-center justify-content-center"
        >
            <b-button
                v-if="currentViewStore.getViewName"
                pill
                disabled
                size="sm"
                variant="light"
            >
                {{ currentViewStore.getViewName }}
            </b-button>
        </b-col>
        <b-col
            cols="5"
            class="p-0 d-flex align-items-center justify-content-end"
        >
            <b-navbar-nav>
                <b-nav-item-dropdown>
                    <template #button-content> Data import </template>
                    <b-dropdown-item
                        @click="emit('show-market-data-dialog')"
                    >
                        Market data
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="emit('show-deposit-model-dialog')"
                    >
                        Models
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="showDataExport">
                    <template #button-content> Data export </template>
                    <b-dropdown-item
                        @click="emit('export-market-data')"
                    >
                        Market data
                    </b-dropdown-item>
                    <b-dropdown-item @click="emit('export-models')">
                        Models
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item
                    v-if="userLoginStore.userIsRoot"
                    :to="{ name: 'Companies' }"
                >
                    Companies
                </b-nav-item>
                <b-nav-item v-if="superUser" :to="{ name: 'Users' }">
                    Users
                </b-nav-item>
                <b-nav-text>
                    <Icon icon="fluent-mdl2:separator" width="24" />
                </b-nav-text>
                <b-nav-text disabled>
                    <b>{{ userLoginStore.getCompanyName }}</b> -
                    <em>{{ userLoginStore.userName }}</em>
                </b-nav-text>
                <b-nav-item @click="emit('logout')">
                    <Icon icon="material-symbols:logout" width="24" />
                </b-nav-item>
            </b-navbar-nav>
        </b-col>
    </b-navbar>
</template>

<style>
    .icon-logo {
        width: 150px;
        height: auto;
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }
</style>
