export default class Ccy {
    static currencyFormatter = require('currency-formatter');

    static format(money) {
        return Ccy.currencyFormatter.format(money, { locale: 'de-DE' });
    }

    static unformat(money) {
        if (money.endsWith('€')) {
            return Ccy.currencyFormatter.unformat(money, {
                locale: 'de-DE',
            });
        } else {
            return parseFloat(money);
        }
    }
}
