import { defineStore, acceptHMRUpdate } from 'pinia';
import Utils from '@/support/utils';
import { Role } from '@/enums';

/**
 * Returns the default state object for a user.
 *
 * @returns {{
 * userId: number | null,
 * userRole: number | null,
 * userToken: string | null,
 * userInfo: Object | null,
 * localToken: string | null,
 * }} The default state object.
 */
const getDefaultState = () => {
    return {
        userId: null,
        userRole: null,
        userToken: null,
        userInfo: null,
        localToken: null,
    };
};

export const useUserLoginStorePinia = defineStore('user', {
    state: getDefaultState,
    getters: {
        getUserId: (state) => state.userId,
        userIsLogged: (state) => !!state.userId,
        userIsRoot: (state) => state.userRole === Role.ROOT,
        userIsAdmin: (state) => state.userRole === Role.ADMIN,
        authorization: (state) => ({
            headers: {
                Authorization: `Bearer ${state.userToken}`,
            },
        }),
        userName: (state) =>
            `${state.userInfo?.firstName} ${state.userInfo?.lastName}`,
        userMail: (state) => `${state.userInfo?.email}`,
        initials: (state) =>
            `${state.userInfo?.firstName?.[0]}${state.userInfo?.lastName?.[0]}`,
        getLocalToken: (state) => state.localToken,
        companyId: (state) => state.userInfo?.companyId,
        isModuleEnabled: (state) => (moduleCode) =>
            state.userInfo?.license?.options.includes(moduleCode),
        getCompanyName: (state) => state.userInfo?.companyName,
    },
    actions: {
        /**
         * Initializes the user data.
         *
         * @param {Object} [userData] - The user data.
         * @param {number|null} [userData.id] - The user's ID.
         * @param {number|null} [userData.role] - The user's role.
         * @param {string|null} [userData.token] - The user's token.
         * @param {Object|null} [userData.info] - The user's additional information.
         */
        init(
            userData = {
                id: null,
                role: null,
                token: null,
                info: null,
            }
        ) {
            this.$patch((state) => {
                if (userData) {
                    state.userId = userData.id;
                    state.userRole = userData.role;
                    state.userToken = userData.token;
                    state.userInfo = userData.info;
                } else {
                    state.userId = null;
                    state.userInfo = null;
                }
            });
        },
        setLocalSessionToken() {
            const tkn = Utils.setLocalSessionToken();
            this.localToken = tkn;
        },
        preserveLocalToken() {
            const tkn = this.localToken;
            Utils.preserveLocalToken(tkn);
        },
        setDefaultState() {
            this.$reset();
        },
    },
    persist: true,
});

// babel module.hot supports
if (module.hot) {
    module.hot.accept(
        acceptHMRUpdate(useUserLoginStorePinia, module.hot)
    );
}
