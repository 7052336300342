import Vue from 'vue';

function stripCcy(money) {
    if (money.endsWith('€'))
        return money.substr(0, money.length - 1).trim();
    else return money.trim();
}

function stripTriples(money) {
    return money.replaceAll('.', '').replace(',', '.');
}

function clean(money) {
    return money.replace(/[^0-9.]/g, '');
}

function formatTriple(num) {
    if (num > 99) return `${num}`;
    if (num > 9) return `0${num}`;
    return `00${num}`;
}

function formatCents(num) {
    if (num > 9) return `${num}`;
    return `0${num}`;
}

function formatTriples(num) {
    const bigNum = Math.round(num * 100);
    const cents = bigNum % 100;
    let intNum = (bigNum - cents) / 100;
    const triples = [];
    while (intNum > 0) {
        const triple = intNum % 1000;
        triples.unshift(triple);
        intNum = (intNum - triple) / 1000;
    }
    let intStr = triples.length > 0 ? `${triples[0]}` : '0';
    for (let i = 1; i < triples.length; i++)
        intStr = `${intStr}.${formatTriple(triples[i])}`;
    const str = `${intStr},${formatCents(cents)}`;
    return str;
}

// Initialize the annoying-background directive.
export const CurrencyDirective = {
    bind(el, binding, vnode) {
        // Focus the element
        el.addEventListener('focus', (e) => {
            el.previous = el.value;
            const num0 = stripCcy(el.value);
            const num1 = stripTriples(num0);
            el.value = num1;
        });
        el.addEventListener('blur', (e) => {
            const cleanNum = clean(el.value);
            const num = cleanNum.length > 0 ? parseFloat(cleanNum) : 0;
            el.value = `${formatTriples(num)} €`;
        });
    },
};

// You can also make it available globally.
Vue.directive('ccy', CurrencyDirective);
